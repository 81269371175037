.navbar {
    /* border-bottom: solid 1px #ebebeb; */
}
.bottom-tab-nav {
    border-top: solid 1px #ebebeb;
}
.nav-link,
.bottom-nav-link {
    color: #55575b;
}
.bottom-nav-link.active {
    color: #922c88;
    /* color: greenyellow; */
}
.bottom-tab-label {
    font-size: 12px;
}
.body {
    width: 100%;
}

.center-container {
    min-height: 20px;
    position: relative;
    /* border: 3px solid green; */
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.user-send-image {
    text-align: left !important;
}

body,
html {
    height: 100%; /* Ensure body and html element fill entire viewport */
    margin: 0; /* Remove default margin */
    overflow-x: hidden;
}

#root {
    height: 100%;
    width: 100vw;
    /* background-color: lightblue; */
    display: flex;
    flex-direction: column;
}

.vertical-center-parent {
    position: relative;
}

.vertical-center-child {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

@media (max-width: 991px) {
    .main-container {
        max-height: 88%;
        /* height: auto;
    }
}
@media (min-width: 992px) {
    .main-container {
        /* min-height: 84%;
        height: auto; */
    }
}

@media (min-width: 668px) and (max-width: 700px) {
    /* .main-container {
        height: 79%;
        overflow: auto;
    } */
}

@media (max-height: 668px) {
    /* .main-container {
        height: 79%;
        overflow: auto;
    } */
}

span {
    overflow: hidden; /* Hide overflowing content */
    text-overflow: ellipsis; /* Show ellipsis (...) for truncated text */
    white-space: nowrap; /* Prevent wrapping of text */
}

.footer-space {
    height: 60px;
}
