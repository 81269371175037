.card {
    /* padding: 10px; */
    /* box-shadow: 00 5px #ffdfdf; */
    /* border-radius: 5px; */
    overflow: hidden;
    border: 0 !important;
}
.card .top {
    text-align: center;
}
.card p {
    font-weight: bold;
    color: #0086fe;
}
.card button {
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #0086fe;
}
.card .drag-area {
    height: 100px;
    border-radius: 5px;
    border: 2px dashed #0086fe;
    color: #0086fe;
    background: #f4f3f9;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    margin-top: 10px;
}
.card .drag-area .visible {
    font-size: 18px;
}
.card .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}
.card .select:hover {
    opacity: 0.6;
}
.card .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 360px;
    overflow-y: auto;
    /* margin-top: 10px; */
}
.card .container .image {
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}
.card .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
.card .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}
.card input,
.card .drag-area .on-drop,
.card .drag-area.dragover .visible {
    display: none;
}
.delete {
    z-index: 999;
    /* color: #0086fe; */
    color: rgb(221, 18, 18);
}

.ghoptions input {
    display: block;
    border-radius: 4px;
    border: 1px solid gray;
}

.lang-select-div button {
    border: 1px solid gray;
    background: transparent !important;
    color: gray;
}

.message-list-container {
    max-height: calc(var(--screen-height) * 0.1);
    overflow: auto; /* Add scrollbars if content overflows */
}

@media (max-width: 991px) {
    .about-main-container {
        height: 92%;
        /* height: 90%; */
    }
    .about-footer-space {
        height: 120px !important;
    }
}
@media (min-width: 992px) {
    .about-main-container {
        /* height: 84%; */
        height: 86%;
        overflow: auto;
    }
}
