/* @media (min-width: 771px) {
    .phone-carousel-news {
        display: true;
    }
}

@media (max-width: 770px) {
    .computer-carousel-news {
        display: true;
    }
} */

.phone-carousel-news {
    max-height: 350px;
}
